// AUTHENTICATION
export const LOGGING_USER_IN = "LOGGING_USER_IN";
export const SESSION_LOGIN_FAILED = "SESSION_LOGIN_FAILED";
export const SESSION_LOGIN_FAILED_INVALID_EMAIL =
  "SESSION_LOGIN_FAILED_INVALID_EMAIL";
export const REDIRECT_NEW_EMAIL_SIGNUP = "REDIRECT_NEW_EMAIL_SIGNUP";
export const LOGOUT = "LOGOUT";
export const DELETED_USER = "DELETED_USER";

// FOR CONTACT US
export const SUBMITTING_CONTACT_US = "SUBMITTING_CONTACT_US";
export const SUBMIT_CONTACT_US_SUCCEED = "SUBMIT_CONTACT_US_SUCCEED";
export const SUBMIT_CONTACT_US_FAILED = "SUBMIT_CONTACT_US_FAILED";

// REGISTRATION
export const REGISTERING_ACCOUNT = "REGISTERING_USER";
export const REGISTER_ACCOUNT_SUCCEED = "REGISTER_ACCOUNT_SUCCEED";
export const REGISTER_ACCOUNT_FAILED = "REGISTER_ACCOUNT_FAILED";

// SOCIAL MEDIA LOGIN
export const SOCIAL_LOGIN = "SOCIAL_LOGIN";
export const SOCIAL_LOGIN_SUCCEED = "SOCIAL_LOGIN_SUCCEED";
export const SOCIAL_LOGIN_FAILED = "SOCIAL_LOGIN_FAILED";

// ONBOARDING
export const ONBOARD_SUCCEED = "ONBOARD_SUCCEED";
export const ONBOARD_FAILED = "ONBOARD_FAILED";
export const UPDATE_ONBOARDING_DIALOG_STATUS =
  "UPDATE_ONBOARDING_DIALOG_STATUS";
export const VALIDATE_FULLNAME_SUCCEED = "VALIDATE_FULLNAME_SUCCEED";
export const VALIDATE_FULLNAME_FAILED = "VALIDATE_FULLNAME_FAILED";
export const UPDATE_ONBOARDING_FIELDS = "UPDATE_ONBOARDING_FIELDS";

// RESET ONBOARDING FIELDS
export const RESET_ONBOARDING_FIELDS = "RESET_ONBOARDING_FIELDS";

// RESET ONBOARDING STATUS
export const RESET_ONBOARDING_STATUS = "RESET_ONBOARDING_STATUS";

// UPDATE ONBOARDING STATUS
export const UPDATE_ONBOARDING_STATUS = "UPDATE_ONBOARDING_STATUS";

// USER LOGGED IN INFO
export const USER_TOKEN = "USER_TOKEN";
export const USER_PROFILE = "USER_PROFILE";

// USER ACTION
export const UPDATE_USER = "UPDATE_USER";

// UPDATING TALENT AVAILABLE STATUS
export const UPDATING_TALENT_AVAILABLE = "UPDATING_TALENT_AVAILABLE";
export const UPDATE_TALENT_AVAILABLE_SUCCEED =
  "UPDATE_TALENT_AVAILABLE_SUCCEED";
export const UPDATE_TALENT_AVAILABLE_FAILED = "UPDATE_TALENT_AVAILABLE_FAILED";

// UPDATING USER PROFILE
export const UPDATING_USER_PROFILE = "UPDATING_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCEED = "UPDATE_USER_PROFILE_SUCCEED";
export const UPDATE_USER_PROFILE_FAILED = "UPDATE_USER_PROFILE_FAILED";
export const UPDATE_PROFILE_FIELDS = "UPDATE_PROFILE_FIELDS";
export const CLEAR_PROFILE_FIELDS = "CLEAR_PROFILE_FIELDS";

// FETCH USER WORK PERSONA
export const FETCHING_USER_WORK_PERSONA = "FETCHING_USER_WORK_PERSONA";
export const FETCH_USER_WORK_PERSONA_SUCCEED =
  "FETCH_USER_WORK_PERSONA_SUCCEED";

// For job preference tab
export const UPDATE_JOB_PREF_SKILLS_SUCCEED = "UPDATE_JOB_PREF_SKILLS_SUCCEED";

// GETTING USER INFO
export const FETCHING_USER_INFO = "FETCHING_USER_INFO";
export const FETCH_USER_INFO_SUCCEED = "FETCH_USER_INFO_SUCCEED";
export const FETCH_USER_INFO_FAILED = "FETCH_USER_INFO_FAILED";

// GETTING COUNTRY PREFERENCE
export const FETCHING_COUNTRY_PREFERENCE = "FETCHING_COUNTRY_PREFERENCE";
export const FETCH_COUNTRY_PREFERENCE_SUCCEED =
  "FETCH_COUNTRY_PREFERENCE_SUCCEED";
export const FETCH_COUNTRY_PREFERENCE_FAILED =
  "FETCH_COUNTRY_PREFERENCE_FAILED";

// UPDATING COUNTRY PREFERENCE
export const UPDATING_COUNTRY_PREFERENCE = "UPDATING_COUNTRY_PREFERENCE";
export const UPDATE_COUNTRY_PREFERENCE_SUCCEED =
  "UPDATE_COUNTRY_PREFERENCE_SUCCEED";
export const UPDATE_COUNTRY_PREFERENCE_FAILED =
  "UPDATE_COUNTRY_PREFERENCE_FAILED";

// UPDATING TALENT SEARCH
export const UPDATING_TALENT_SEARCH = "UPDATING_TALENT_SEARCH";
export const UPDATE_TALENT_SEARCH_SUCCEED = "UPDATE_TALENT_SEARCH_SUCCEED";
export const UPDATE_TALENT_SEARCH_FAILED = "UPDATE_TALENT_SEARCH_FAILED";

// UPDATING USER RESUME
export const UPDATING_USER_CV = "UPDATING_USER_CV";
export const UPDATE_USER_CV_SUCCEED = "UPDATE_USER_CV_SUCCEED";
export const UPDATE_USER_CV_FAILED = "UPDATE_USER_CV_FAILED";

// UPDATING USER PROFILE IMAGE
export const UPDATING_USER_PROFILE_IMAGE = "UPDATING_USER_PROFILE_IMAGE";
export const UPDATE_USER_PROFILE_IMAGE_SUCCEED =
  "UPDATE_USER_PROFILE_IMAGE_SUCCEED";
export const UPDATE_USER_PROFILE_IMAGE_FAILED =
  "UPDATE_USER_PROFILE_IMAGE_FAILED";

// FOR VCL UPLOAD
export const FETCHING_PRESIGNED_URL = "FETCHING_PRESIGNED_URL";
export const FETCH_PRESIGNED_URL_SUCCEED = "FETCH_PRESIGNED_URL_SUCCEED";
export const FETCH_PRESIGNED_URL_FAILED = "FETCH_PRESIGNED_URL_FAILED";
export const UPDATING_VCL_PUBLIC_URL = "UPDATING_VCL_PUBLIC_URL";
export const UPDATE_VCL_PUBLIC_URL_SUCCEED = "UPDATE_VCL_PUBLIC_URL_SUCCEED";
export const UPDATE_VCL_PUBLIC_URL_FAILED = "UPDATE_VCL_PUBLIC_URL_FAILED";

// FOR CHAT
export const FETCHING_CHAT_LIST = "FETCHING_CHAT_LIST";
export const FETCH_CHAT_LIST_SUCCEED = "FETCH_CHAT_LIST_SUCCEED";
export const FETCH_CHAT_LIST_FAILED = "FETCH_CHAT_LIST_FAILED";
export const FETCHING_CLOSED_CHAT_LIST = "FETCHING_CLOSED_CHAT_LIST";
export const FETCH_CLOSED_CHAT_LIST_SUCCEED = "FETCH_CLOSED_CHAT_LIST_SUCCEED";
export const FETCH_CLOSED_CHAT_LIST_FAILED = "FETCH_CLOSED_CHAT_LIST_FAILED";
export const UPDATE_CURRENT_CHAT = "UPDATE_CURRENT_CHAT";
export const FETCHING_CHAT_MESSAGES = "FETCHING_CHAT_MESSAGES";
export const FETCH_CHAT_MESSAGES_SUCCEED = "FETCH_CHAT_MESSAGES_SUCCEED";
export const FETCH_CHAT_MESSAGES_FAILED = "FETCH_CHAT_MESSAGES_FAILED";
export const SENDING_MESSAGE = "SENDING_MESSAGE";
export const SEND_MESSAGE_SUCCEED = "SEND_MESSAGE_SUCCEED";
export const SEND_MESSAGE_FAILED = "SEND_MESSAGE_FAILED";
export const ADD_NEW_MESSAGE = "ADD_NEW_MESSAGE";
export const REPLACING_CHAT_FROM_LIST = "REPLACING_CHAT_FROM_LIST";
export const COUNT_UNREAD_MESSAGE = "COUNT_UNREAD_MESSAGE";

// CHANGING USER PASSWORD
export const CHANGING_USER_PASSWORD = "CHANGING_USER_PASSWORD";
export const CHANGING_USER_PASSWORD_SUCCEED = "CHANGING_USER_PASSWORD_SUCCEED";
export const CHANGING_USER_PASSWORD_FAILED = "CHANGING_USER_PASSWORD_FAILED";

// FORGETTING PASSWORD
export const FORGETTING_PASSWORD = "FORGETTING_PASSWORD";
export const FORGET_PASSWORD_SUCCEED = "FORGET_PASSWORD_SUCCEED";
export const FORGET_PASSWORD_FAILED = "FORGET_PASSWORD_FAILED";

// PASSWORD RESET
export const RESETTING_PASSWORD = "RESETTING_PASSWORD";
export const RESET_PASSWORD_SUCCEED = "RESET_PASSWORD_SUCCEED";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

// USER PROFILE DRAWER
export const UPDATE_DRAWER_INDEX = "UPDATE_DRAWER_INDEX";

// USER PROFILE UPDATE
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER";

// USER PROFILE SKILLS SECTION
export const UPDATE_LIGHTCAST_SKILLS_LIST = "UPDATE_LIGHTCAST_SKILLS_LIST";
export const RESET_LIGHTCAST_SKILLS_LIST = "RESET_LIGHTCAST_SKILLS_LIST";
export const UPDATE_LIGHTCAST_CERTIFICATES_LIST =
  "UPDATE_LIGHTCAST_CERTIFICATES_LIST";
export const UPDATE_USER_SKILLS_LIST = "UPDATE_USER_SKILLS_LIST";
export const REMOVE_USER_SKILL_FROM_LIST = "REMOVE_USER_SKILL_FROM_LIST";
export const SAVING_USER_SKILLS = "SAVING_USER_SKILLS";
export const SAVE_USER_SKILLS_STATUS = "SAVE_USER_SKILLS_STATUS";
export const UPDATE_USER_SKILL_FAILED = "UPDATE_USER_SKILL_FAILED";

// USER PROFILE CERTIFICATES AND TRAINING SECTION
export const UPDATE_CERTIFICATES = "UPDATE_CERTIFICATES";
export const UPDATE_CERTIFICATES_SUCCEED = "CREATE_CERTIFICATES_SUCCEED";
export const UPDATE_CERTIFICATES_FAILED = "CREATE_CERTIFICATES_FAILED";
export const UPDATE_CERTIFICATES_FORMS = "UPDATE_CERTIFICATES_FORMS";
export const DELETE_CERTIFICATES = "DELETE_CERTIFICATES";

// USER PROFILE EDUCATION SECTION
export const UPDATE_EDUCATION_FORM_STATE = "UPDATE_EDUCATION_FORM_STATE";
export const UPDATE_USER_EDUCATION_LIST = "UPDATE_USER_EDUCATION_LIST";
export const UPDATING_USER_EDUCATION = "UPDATING_USER_EDUCATION";

// USER PROFILE HONOURS AND AWARDS SECTION
export const UPDATE_HONOURS = "UPDATE_HONOURS";
export const UPDATE_HONOURS_SUCCEED = "CREATE_HONOURS_SUCCEED";
export const UPDATE_HONOURS_FAILED = "CREATE_HONOURS_FAILED";
export const DELETE_HONOURS = "DELETE_HONOURS";

// USER PROFILE EXTRACURRICULAR SECTION
export const UPDATE_EXTRACURRICULAR = "UPDATE_EXTRACURRICULAR";
export const UPDATE_EXTRACURRICULAR_SUCCEED = "UPDATE_EXTRACURRICULAR_SUCCEED";
export const UPDATE_EXTRACURRICULAR_FAILED = "UPDATE_EXTRACURRICULAR_FAILED";
export const UPDATE_EXTRACURRICULAR_FORMS = "UPDATE_EXTRACURRICULAR_FORMS";
export const DELETE_EXTRACURRICULAR = "DELETE_EXTRACURRICULAR";

// USER PROFILE WORK EXPERIENCE SECTION
export const UPDATE_WORK = "UPDATE_WORK";
export const UPDATE_WORK_SUCCEED = "UPDATE_WORK_SUCCEED";
export const UPDATE_WORK_FAILED = "UPDATE_WORK_FAILED";

export const UPDATE_EDUCATION_FAILED = "UPDATE_EDUCATION_FAILED";
export const RESET_EDUCATION_FORM = "RESET_EDUCATION_FORM";

// FOR NEW GETTING USER INFO
export const FETCH_NEW_USER_INFO_SUCCEED = "FETCH_NEW_USER_INFO_SUCCEED";

// FOR NEW LOGIN
export const NEW_SESSION_LOGIN_SUCCEED = "NEW_SESSION_LOGIN_SUCCEED";

// USER PROFILE LANGUAGE SECTION
export const SAVING_USER_LANGUAGES = "SAVING_USER_LANGUAGES";
export const SAVING_USER_LANGUAGES_FAIL = "SAVING_USER_LANGUAGES_FAIL";
export const SAVE_USER_UPDATED_LANGUAGES = "SAVE_USER_UPDATED_LANGUAGES";

// USER PROFILE PROJECT SECTION
export const SAVING_USER_PROJECTS = "SAVING_USER_PROJECTS";
export const SAVE_USER_UPDATED_PROJECTS = "SAVE_USER_UPDATED_PROJECTS";
export const UPDATE_PROJECT_FORM_STATE = "UPDATE_PROJECT_FORM_STATE";
export const UPDATE_PROJECTS_FAILED = "UPDATE_PROJECTS_FAILED";
export const RESET_PROJECT_FORM_STATE = "RESET_PROJECT_FORM_STATE";

// USER BASIC INFO
export const UPDATE_USER_BASIC_INFO = "UPDATE_USER_BASIC_INFO";
export const UPDATE_USER_BASIC_INFO_FAILED = "UPDATE_USER_BASIC_INFO_FAILED";

// FOR RESUME REPLACEMENT POPUP
export const UPDATE_REPLACE_RESUME_POPUP_STATUS =
  "UPDATE_REPLACE_RESUME_POPUP_STATUS";

// FOR CREATING/UPLOADING/DONE RESUME STATUS POPUP
export const UPDATE_RESUME_PROGRESS_POPUP_STATUS =
  "UPDATE_RESUME_PROGRESS_POPUP_STATUS";

export const UPDATE_RESUME_FAIL_POPUP_STATUS =
  "UPDATE_RESUME_FAIL_POPUP_STATUS";

export const UPDATE_RESUME_BUILDING_STATUS = "UPDATE_RESUME_BUILDING_STATUS";

export const UPDATE_RESUME_UPLOADING_STATUS = "UPDATE_RESUME_UPLOADING_STATUS";

export const UPDATE_RESUME_DONE_STATUS = "UPDATE_RESUME_DONE_STATUS";

// FOR VALIDATING HIREDLY RESUME CONTENT
export const VALIDATE_HIREDLY_RESUME = "VALIDATE_HIREDLY_RESUME";
export const VALIDATE_HIREDLY_RESUME_SUCCEED =
  "VALIDATE_HIREDLY_RESUME_SUCCEED";
export const VALIDATE_HIREDLY_RESUME_FAILED = "VALIDATE_HIREDLY_RESUME_FAILED";

// FOR CREATE RESUME POPUP
export const UPDATE_CREATE_RESUME_POPUP_STATUS =
  "UPDATE_CREATE_RESUME_POPUP_STATUS";

// FOR REJECTED RESUME POPUP
export const UPDATE_REJECTED_RESUME_POPUP_STATUS =
  "UPDATE_REJECTED_RESUME_POPUP_STATUS";

// FOR IS HIREDLY RESUME REJECTED STATUS
export const UPDATE_IS_HIREDLY_RESUME_REJECTED_STATUS =
  "UPDATE_IS_HIREDLY_RESUME_REJECTED_STATUS";

// FOR IN RESUME RESUME POPUP
export const UPDATE_IN_REVIEW_RESUME_POPUP_STATUS =
  "UPDATE_IN_REVIEW_RESUME_POPUP_STATUS";

// FOR PREVIEW RESUME POPUP
export const UPDATE_PREVIEW_RESUME_POPUP_STATUS =
  "UPDATE_PREVIEW_RESUME_POPUP_STATUS";

// FOR FAILED UPLOAD RESUME POPUP
export const UPDATE_FAILED_UPLOAD_RESUME_POPUP_STATUS =
  "UPDATE_FAILED_UPLOAD_RESUME_POPUP_STATUS";

// FOR LINKEDIN RESUME POPUP
export const UPDATE_LINKEDIN_RESUME_POPUP_STATUS =
  "UPDATE_LINKEDIN_RESUME_POPUP_STATUS";

export const GET_RESUME_FAILED = "GET_RESUME_FAILED";
export const GET_RESUME_SUCCESS = "GET_RESUME_SUCCESS";

// FOR USER PROFILE TOOLTIP
export const SET_CURRENT_TOOLTIP = "SET_CURRENT_TOOLTIP";
export const RESET_CURRENT_TOOLTIP = "RESET_CURRENT_TOOLTIP";

// UPDATE_CURRENT_ONBOARDING_PAGE
export const UPDATE_CURRENT_ONBOARDING_PAGE = "UPDATE_CURRENT_ONBOARDING_PAGE";
export const UPDATE_JOB_TITLE_DROPDOWN_STATUS =
  "UPDATE_JOB_TITLE_DROPDOWN_STATUS";
export const UPDATE_INDUSTRY_DROPDOWN_STATUS =
  "UPDATE_INDUSTRY_DROPDOWN_STATUS";
export const UPDATE_SPECIALISATION_DROPDOWN_STATUS =
  "UPDATE_SPECIALISATION_DROPDOWN_STATUS";
export const UPDATE_MAJOR_DROPDOWN_STATUS = "UPDATE_MAJOR_DROPDOWN_STATUS";
export const UPDATE_SKILL_DROPDOWN_STATUS = "UPDATE_SKILL_DROPDOWN_STATUS";
export const UPDATE_PROGRESS_BAR_VALUE = "UPDATE_PROGRESS_BAR_VALUE";

// UPDATE ONBOARDING INFO
export const UPDATE_ONBOARDING_INFO = "UPDATE_ONBOARDING_INFO";
export const UPDATE_ONBOARDING_INFO_SUCCEED = "UPDATE_ONBOARDING_INFO_SUCCEED";
export const UPDATE_ONBOARDING_INFO_FAILED = "UPDATE_ONBOARDING_INFO_FAILED";

// ONBOARDING JOB TITLE LIGHT CAST
export const UPDATE_LIGHTCAST_JOBS_LIST = "UPDATE_LIGHTCAST_JOBS_LIST";

export const UPDATE_SELECTED_RECOMMENDED_JOBS =
  "UPDATE_SELECTED_RECOMMENDED_JOBS";
export const UPDATE_BULK_JOBS_POPUP_STATUS = "UPDATE_BULK_JOBS_POPUP_STATUS";

export const UPDATE_SLIDER_DOTS_HISTORY = "UPDATE_SLIDER_DOTS_HISTORY";

export const REMOVE_PROFILE_PHOTO_SUCCESS = "REMOVE_PROFILE_PHOTO_SUCCESS";
export const REMOVE_PROFILE_PHOTO_FAILED = "REMOVE_PROFILE_PHOTO_FAILED";
export const REMOVE_PROFILE_PHOTO_IN_PROGRESS =
  "REMOVE_PROFILE_PHOTO_IN_PROGRESS";

export const UPDATE_LANGUAGE_CHECK_POPUP_STATUS =
  "UPDATE_LANGUAGE_CHECK_POPUP_STATUS";

// JOB COUNTRY PREFERENCE DIALOG
export const UPDATE_JOB_COUNTRY_PREFERENCE_DIALOG =
  "UPDATE_JOB_COUNTRY_PREFERENCE_DIALOG";
export const UPDATE_JOB_COUNTRY_PREFERENCE = "UPDATE_JOB_COUNTRY_PREFERENCE";

// NATIONALITY & MOBILE NUMBER DIALOG
export const UPDATE_NATIONALITY_AND_MOBILE_NUMBER_DIALOG =
  "UPDATE_NATIONALITY_AND_MOBILE_NUMBER_DIALOG";

// UPDATE NATIONALITY & MOBILE NUMBER
export const UPDATE_NATIONALITY_AND_MOBILE_NUMBER_SUCCESS =
  "UPDATE_NATIONALITY_AND_MOBILE_NUMBER_SUCCESS";
export const UPDATE_NATIONALITY_AND_MOBILE_NUMBER_FAILED =
  "UPDATE_NATIONALITY_AND_MOBILE_NUMBER_FAILED";

// Secondary Visitor
export const UPLOAD_RESUME_SUCCESS = "UPLOAD_RESUME_SUCCESS";

// FOR Ashley Profile Short Summary
export const FETCHING_ASHLEY_SHORT_SUMMARY = "FETCHING_ASHLEY_SHORT_SUMMARY";
export const FETCH_ASHLEY_SHORT_SUMMARY_SUCCEED =
  "FETCH_ASHLEY_SHORT_SUMMARY_SUCCEED";
export const FETCH_ASHLEY_SHORT_SUMMARY_FAILED =
  "FETCH_ASHLEY_SHORT_SUMMARY_FAILED";
export const UPDATE_SELECTED_SHORT_SUMMARY = "UPDATE_SELECTED_SHORT_SUMMARY";

export const FETCH_PARSED_RESUME_SECTIONS_SUCCESS =
  "FETCH_PARSED_RESUME_SECTIONS_SUCCESS";
export const FETCH_PARSED_RESUME_SECTIONS_FAILED =
  "FETCH_PARSED_RESUME_SECTIONS_FAILED";
export const FETCH_EMPTY_PARSED_RESUME_SECTIONS =
  "FETCH_EMPTY_PARSED_RESUME_SECTIONS";

export const UPDATE_ONBOARDING_RESUME_SUCCESS =
  "UPDATE_ONBOARDING_RESUME_SUCCESS";
export const UPDATE_ONBOARDING_RESUME_FAILED =
  "UPDATE_ONBOARDING_RESUME_FAILED";

// FOR SPECIALISATION DROPDOWN
export const OPEN_SPECIALISATION_DROPDOWN = "OPEN_SPECIALISATION_DROPDOWN";
export const OPEN_SUB_SPECIALISATION_DROPDOWN =
  "OPEN_SUB_SPECIALISATION_DROPDOWN";
export const UPDATE_SUB_SPECIALISATION = "UPDATE_SUB_SPECIALISATION";

export const HYDRATE_REDUX_USER = "HYDRATE_REDUX_USER";

// FOR PDPA STATUS
export const FETCH_PDPA_STATUS_SUCCESS = "FETCH_PDPA_STATUS_SUCCESS";
export const UPDATE_PDPA_STATUS_SUCCEED = "UPDATE_PDPA_STATUS_SUCCEED";
export const UPDATE_PDPA_STATUS_FAILED = "UPDATE_PDPA_STATUS_FAILED";

// FOR WORK PERSONA 2.0
export const FETCH_PERSONA_SUCCESS = "FETCH_PERSONA_SUCCESS";
export const FETCH_PERSONA_FAILED = "FETCH_PERSONA_FAILED";

// FOR CHECKING RESUME PARSED STATUS
export const CHECK_RESUME_PARSED_STATUS_SUCCEED =
  "CHECK_RESUME_PARSED_STATUS_SUCCEED";
export const CHECK_RESUME_PARSED_STATUS_FAILED =
  "CHECK_RESUME_PARSED_STATUS_FAILED";

// FOR FETCHING ALL STATE REGIONS
export const GET_ALL_STATE_REGIONS = "GET_ALL_STATE_REGIONS";
